<template>
  <div class="required-fields flex flex-row flex-wrap items-center">
    <div class="flex flex-col">
      <getaway-required-fields-view
        v-if="fields"
        class="p-0 mb-6"
        :fields="fields"
        @form-filling="(formResult) => requiredFieldsForm = formResult"
      />

      <config-field
        :title="$t('required_fields_promocode')"
        class="mb-6"
      >
        <div class="flex flex-row items-center mt-2">
          <i class="material-icons mr-1 text-primary">
            confirmation_number
          </i>
          <a-input v-model="promocode" />
        </div>
      </config-field>

      <a-button
        class="btn-success"
        icon="shop"
        :disabled="isBuyButtonDisabled"
        @click="buy"
      >
        {{ $t('required_fields_buy') }}
      </a-button>
    </div>
  </div>
</template>

<script>
import GetawayRequiredFieldsView from 'piramis-base-components/src/components/ServicesCart/components/GetawayRequiredFieldsView.vue'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

// /add-chat/1637268020876.04d231dfd1e442e89ceaae2dfe7710f1

export default {
  i18n: {
    messages: {
      ru: {
        'required_fields_buy': 'Оплатить',
        'required_fields_promocode': 'Промокод'
      },
      en: {},
    }
  },

  name: "RequiredFields",

  props: [ 'fields', 'value' ],

  data() {
    return {
      requiredFieldsForm: null,
      promocode: ''
    }
  },

  computed: {
    isBuyButtonDisabled() {
      if (this.fields) {
        return this.requiredFieldsForm === null
      }

      return false
    }
  },

  components: {
    GetawayRequiredFieldsView,
    ConfigField
  },

  methods: {
    buy() {
      this.$emit('input', {
        promocode: this.promocode ? this.promocode : null,
        requiredFields: this.requiredFieldsForm ? this.requiredFieldsForm : null
      })
    }
  }
}
</script>

<style lang="scss">
.required-fields {
  .required-shop-params__item {
    padding: 0 !important;
  }

  .getaway-required-field {
    margin-bottom: 10px;
  }
}
</style>
